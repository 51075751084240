import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Deadlift to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Fight Gone Bad”`}</strong></p>
    <p>{`3 rounds, 1:00 each for total reps:`}</p>
    <p>{`Wall Balls (20/14)`}</p>
    <p>{`SDHP (75/55)`}</p>
    <p>{`Box Jumps (20″)`}</p>
    <p>{`Push Press (75/55)`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`1:00 rest.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      